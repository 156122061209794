import {createOptOutCookies, HD_PRIVACY_OPT_OUT} from "./util/PrivacyCookieUtil";
/* global cookieUtils  */

const Service = {
    api: '/customer/gpc/v1/preference',
    call: (body) => {
        return fetch(Service.api, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
    }
}
const Storage = {
    key: 'gpc',
    getGPC: () => {
        try {
            return JSON.parse(window.localStorage.getItem(Storage.key))
        } catch(e) {
            return null;
        }
    },
    setGPC: (obj) => {
        try {
            window.localStorage.setItem(Storage.key, JSON.stringify(obj))
        } catch(e) {}
    },
};

const Model = {
    setCustomer: () => {
        Model.Customer.isLoggedIn = window.THDCustomer.default.isLoggedIn || false;
        Model.Customer.svocID = window.THDCustomer.default.svocID || '';
        Model.Customer.mcvisID = window.THDCustomer.default.mcvisID || window.THDCustomer.default.guestUserID || '';
        Model.Customer.userID = window.THDCustomer.default.userID || '';
    },
    Customer: {},
    forStorage: (storageEntry) => {
        const storageObj = storageEntry || {
            loggedAsGuest: '',
            userIDs: []
        };
        storageObj.loggedAsGuest = !Model.Customer.isLoggedIn;

        if (Model.Customer.isLoggedIn) {
            if (storageObj.userIDs.indexOf(Model.Customer.userID) === -1) {
                storageObj.userIDs.push(Model.Customer.userID);
            }
        }
        return storageObj;
    },
    forAPI: ()=> ({
        mcvisId: Model.Customer.mcvisID,
        svocId: Model.Customer.svocID,
        userId: Model.Customer.userID
    })
}


if (window.localStorage.getItem(HD_PRIVACY_OPT_OUT)) {
    createOptOutCookies(cookieUtils.fed_CreateCookie, window.location.hostname);
}


if (window.navigator && window.navigator.globalPrivacyControl) {
    if (window.THDCustomer && window.THDCustomer.default) {
        Model.setCustomer();
    }
    const storageEntry = Storage.getGPC();

    // if there's no local storage entry at all (first page hit with GPC enabled or after LocalStorage was cleared)
    // or if any of the previous userID's that were logged does not match the current userID (this will also cover the scenario when switching from guest to auth)
    if (!storageEntry || (Model.Customer.isLoggedIn && !storageEntry.userIDs.includes(Model.Customer.userID))) {
        Service.call(Model.forAPI())
            .then(res => {
                if(res.ok) Storage.setGPC(Model.forStorage(storageEntry))
            }).catch((e) => console.log('GPC Request Failed: ', e));
    }
}
