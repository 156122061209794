const COOKIE_GROUPS = 'groups=' + encodeURIComponent('C0001:' + 0 + ','
    + 'C0002:' + 0 + ','
    + 'C0003:' + 0 + ','
    + 'C0004:' + 1 + ','
    + 'C0005:' + 0);

const OPT_OUT_COOKIE_NAME = 'OptOutOnRequest';
const HUNDRED_YEARS_IN_DAYS = 36525;
export const HD_PRIVACY_OPT_OUT = 'HDPrivacyOptOut';

const timezoneDirection = (timezoneOffset) => {
    return (-timezoneOffset) >= 0 ? '+' : '-';
};

const toIsoWithTimezoneString = (date) => {
    const timezone = date.getTimezoneOffset();
    const timezoneSignedDirection = timezoneDirection(timezone);
    const pad = function (num) {
        const norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
    };
    return date.getFullYear()
        + '-' + pad(date.getMonth() + 1)
        + '-' + pad(date.getDate())
        + 'T' + pad(date.getHours())
        + ':' + pad(date.getMinutes())
        + ':' + pad(date.getSeconds())
        + timezoneSignedDirection + pad(timezone / 60)
        + ':' + pad(timezone % 60);
};

function stripSubDomains(domain = '') {
    let parentDomain = domain;

    let domainParts = domain.split('.');
    let length = domainParts.length;
    if (length > 1) {
        parentDomain = domainParts[length - 2] + '.' + domainParts[length - 1];
    }
    return parentDomain;
}

function buildCookieScope(domain = '') {
    return domain
        ? '; domain=' + domain
        : '';
}

function buildCookieTimestamp() {
    return 'datetimestamp=' + encodeURIComponent(toIsoWithTimezoneString(new Date(Date.now())));
}

function buildCookieSettings(domain = '') {
    return buildCookieTimestamp() + '&'
        + COOKIE_GROUPS
        + buildCookieScope(domain);
}

function createOptOutCookie(cookieCallback, domain) {
    cookieCallback(OPT_OUT_COOKIE_NAME, buildCookieSettings(domain), HUNDRED_YEARS_IN_DAYS);
}

export function createOptOutCookies(cookieCallback, domain = '') {
    createOptOutCookie(cookieCallback);
    createOptOutCookie(cookieCallback, stripSubDomains(domain));
}
